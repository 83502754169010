import { useCallback } from 'react'

import get from 'lodash.get'

import { useQuestionTemplate } from '@/hooks/features/useQuestionTemplate'

import { UserParams, getAppPrefillParams } from '../../lib/@getethos/api/api'
import { LGA_AGE_LIMIT } from '../../lib/@getethos/constants'
import { appRoute, navigateToApp } from '../../lib/@getethos/urls/urls'
import { ageFromBirthDate } from '../../lib/@getethos/utils/ageFromBirthDate'
import { mapQueryToUserData } from '../../lib/@getethos/utils/mapQueryToUserData'
import { getQueryString } from '../../lib/@getethos/utils/utils'
import { transmitRiskToken } from '../../lib/TransmitSecurity/riskToken'
import useSiteApiUrl from './useSiteApiUrl'
import useSiteAppUrl from './useSiteAppUrl'

interface HookReturn {
  handleNavigateToApp: (
    userData: Partial<UserParams>,
    openNewTab?: boolean,
    needs?: boolean,
    ratesCopy?: boolean,
    clickthroughUrl?: string,
    flow?: string
  ) => void
}

//pcode for partner attribution
const PARTNER_CODE = 'p'
//product type used to override recommended product category in main app
const PRODUCT_TYPE = 'productType'
// custom prefill key for user PII
const PREFILL_KEY = 'pfk'
// custom PreInterview flow
const FLOW = 'flow'
const MUGGLE = 'muggle'

const addExtraUserParams = (
  extraData: Record<string, any>,
  params: Record<string, any>
): void => {
  params['utm_address'] = extraData.address
  params['utm_address_2'] = extraData.addressTwo
  params['utm_city'] = extraData.city
  params['utm_email'] = extraData.email
  params['utm_first_name'] = extraData.firstName
  params['utm_last_name'] = extraData.lastName
  params['utm_middle_name'] = extraData.middleName
  if (!(extraData.firstName || extraData.middleName || extraData.lastName)) {
    params['utm_name'] = extraData.name
  }
  params['utm_phone'] = extraData.phone
  if (!params.state) {
    //This if statement will never pass in the current workflow
    params['utm_state'] = extraData.state
  }
  if (!params.utm_source) {
    params['utm_source'] = extraData.utmSource
  }
}

export const useNavigateToApp = (): HookReturn => {
  const { isTreatment, isLoading } = useQuestionTemplate()
  let redirectUrl = useSiteAppUrl()
  const apiUrl = useSiteApiUrl()
  let overrideAppUrl = false

  if (!redirectUrl) {
    throw new TypeError('redirectUrl is undefined')
  }

  const handleNavigateToApp = useCallback(
    (
      userData: Partial<UserParams>,
      openNewTab = false,
      needs = false,
      ratesCopy = false,
      clickthroughUrl = '',
      flow?: string
    ): void => {
      transmitRiskToken()

      if (isTreatment && !isLoading) {
        redirectUrl = '/app/flow/question-template'
        overrideAppUrl = true
      } else if (clickthroughUrl !== '' || flow) {
        redirectUrl = clickthroughUrl || `/app/flow/${flow}`
        overrideAppUrl = true
      }

      // Use the current (deployed) domain if we're not on localhost
      const currentDomain = window.location.hostname
        .split('.')
        .slice(-2)
        .join('.')
      if (!currentDomain.startsWith('localhost')) {
        redirectUrl = redirectUrl.replace('ethoslife.com', currentDomain)
      }

      // Override the old "stage" subdomain for the new staging.ethos.com
      redirectUrl = redirectUrl.replace('stage.ethos.com', 'staging.ethos.com')

      const params = getAppPrefillParams(userData)
      const { extraUserData } = userData
      if (extraUserData) {
        addExtraUserParams(extraUserData, params)
      } else {
        const originalUserData = mapQueryToUserData(getQueryString() || {})
        const extraUserData = originalUserData.extraUserData
        if (extraUserData) {
          addExtraUserParams(extraUserData, params)
        }
      }

      // Can be set by editor in admin
      params[FLOW] = userData.flow
      params[PRODUCT_TYPE] = userData.productType

      if (needs) {
        // let needs flow parse state from zipcode
        delete params.state
        const url = overrideAppUrl ? redirectUrl : appRoute.needs(redirectUrl)
        //navigate to needs flow
        navigateToApp(url, apiUrl, params, openNewTab, overrideAppUrl)
      } else {
        const partnerCode = get(getQueryString(), PARTNER_CODE)
        const productTypeQueryString = get(getQueryString(), PRODUCT_TYPE)
        const prefillKey = get(getQueryString(), PREFILL_KEY)

        // add params from query string to pass to main app,
        // if not overridden by editor
        params[PARTNER_CODE] = partnerCode
        if (!userData.productType && productTypeQueryString) {
          params[PRODUCT_TYPE] = productTypeQueryString
        }

        if (prefillKey) {
          params[PREFILL_KEY] = prefillKey
        }

        // Exclude older users from nap flow when sent to main app
        const birthDate = get(userData, 'birthDate')
        const age = !Number.isNaN(Number(birthDate))
          ? Number(birthDate)
          : ageFromBirthDate({ birthDate })
        const userWithinLgaAge = (age || 0) <= LGA_AGE_LIMIT
        if (typeof birthDate !== 'undefined' && !userWithinLgaAge) {
          params[FLOW] = flow || MUGGLE
        }

        if (ratesCopy) {
          params['lpCopy'] = 'ratesCopy'
        }

        const url = overrideAppUrl ? redirectUrl : appRoute.term(redirectUrl)
        //navigate to main pre interview

        navigateToApp(url, apiUrl, params, openNewTab, overrideAppUrl)
      }
    },
    []
  )

  return { handleNavigateToApp }
}
