import React from 'react'

import { ENABLE_CMS_EW_REFACTOR } from 'lib/FeaturesAndExperiments/featureStore'
import { useFeatureVariable } from 'lib/FeaturesAndExperiments/getFeatureVariable'

import { useDirectToAppUx } from '@/hooks/features/useDirectToAppUx'
import { useEwCopy } from '@/hooks/features/useEwCopy'
import { useFeWidget } from '@/hooks/features/useFeWidget'
import { usePaidLandersUpdate } from '@/hooks/features/usePaidLandersUpdate'

import { useUpdatedCpFeLp } from '../../hooks/features/useUpdatedCpFeLp'
import { Loader } from '../global/Loader/Loader'
import styles from './EstimateWidget.module.scss'
import { FloatingWidget } from './FloatingVariation/FloatingWidget'
import { HeroBoxedWidget } from './HeroBoxedVariation/HeroBoxedWidget'
import { EstimateWidgetVariations } from './common/variations'

export enum EstimateWidgetImageLayout {
  BackgroundImage = 'backgroundImage',
  MultiImage = 'multiImage',
  FullBleed = 'fullBleed',
  CoBranded = 'coBranded',
  ForegroundImage = 'foregroundImage',
  StraightToApp = 'straightToApp',
  External = 'external',
  FinalExpense = 'finalExpense',
}

export interface EstimateWidgetModuleData {
  type: string
  variation: EstimateWidgetVariations
  backgroundColor?:
    | 'buttercup'
    | 'duckegg'
    | 'forest'
    | 'gray'
    | 'moss'
    | 'sand'
    | 'white'
  heading?: string
  heading2?: string
  enableH1?: boolean
  headingSize?: string
  subHeading?: string
  subHeading2?: string
  displaySubHeading?: boolean
  displaySubHeading2?: boolean
  groupImage?: string
  hideMediaMobile?: boolean
  imageAlt?: string
  firstButton?: string
  continueLabel?: string
  saveLabel?: string
  disclaimerText?: string
  disclaimerColor?: 'white' | 'darkGrey'
  genderLabel?: string
  showDesiredCoverage?: boolean
  desiredCoverageLabel?: string
  birthLabel?: string
  nicotineLabel?: string
  healthLabel?: string
  zipLabel?: string
  displayEstimatedCredit?: boolean
  estimatedCreditLabel?: string
  estimatedCreditFootnote?: string
  coverageLabel?: string
  trustImageGroups?: Array<{
    groupImage: string
    imageAlt: string
  }>
  termLabel?: string
  emailHeading?: string
  emailSubHeading?: string
  emailSendLabel?: string
  useFinalExpensePriceRange?: boolean
  imageLayout?: EstimateWidgetImageLayout | null
  supportingImage?: string
  supportingImageAlt?: string
  estimateInfoCopy?: string
  estimateRangeCopy?: string
  dailyDollars?: boolean
  dailyPremium?: boolean
  priceElasticity?: boolean
  priceAnchoring?: boolean
  showSinglePrice?: boolean
  salamanderForm?: boolean
  cypressForm?: boolean
  subText?: string
  ratesCopy?: boolean
  displayTrust?: boolean
  disableSave?: boolean
  experimentKey?: string
  disablePresets?: boolean
  treatmentVariant?: string
}

const EstimateWidget = ({
  moduleData,
}: {
  moduleData: EstimateWidgetModuleData
}) => {
  if (moduleData?.dailyPremium) {
    moduleData.estimateRangeCopy = 'Starting at:'
  }
  const { isLoading: isLoadingDirectToAppUx } = useDirectToAppUx()

  const { isLoading: isLoadingFeWidget } = useFeWidget()

  // -- EW Copy experiment start --
  const {
    isLoading: isEwCopyLoading,
    copy: ew_copy,
    isTreatment1,
    isTreatment2,
    isTreatment3,
    isTreatment4,
  } = useEwCopy()

  const { isLoading: isLoadingUpdatedCp } = useUpdatedCpFeLp()

  if (isTreatment1) {
    moduleData.estimateInfoCopy = ew_copy.TREATMENT_1.headline_1
    moduleData.firstButton = ew_copy.TREATMENT_1.cta_1
    moduleData.estimateRangeCopy = ew_copy.TREATMENT_1.headline_2
    moduleData.continueLabel = ew_copy.TREATMENT_1.cta_2
  } else if (isTreatment2) {
    moduleData.estimateInfoCopy = ew_copy.TREATMENT_2.headline_1
    moduleData.firstButton = ew_copy.TREATMENT_2.cta_1
    moduleData.estimateRangeCopy = ew_copy.TREATMENT_2.headline_2
    moduleData.continueLabel = ew_copy.TREATMENT_2.cta_2
  } else if (isTreatment3) {
    moduleData.estimateInfoCopy = ew_copy.TREATMENT_3.headline_1
    moduleData.firstButton = ew_copy.TREATMENT_3.cta_1
    moduleData.estimateRangeCopy = ew_copy.TREATMENT_3.headline_2
    moduleData.continueLabel = ew_copy.TREATMENT_3.cta_2
  } else if (isTreatment4) {
    moduleData.estimateInfoCopy = ew_copy.TREATMENT_4.headline_1
    moduleData.firstButton = ew_copy.TREATMENT_4.cta_1
    moduleData.estimateRangeCopy = ew_copy.TREATMENT_4.headline_2
    moduleData.continueLabel = ew_copy.TREATMENT_4.cta_2
  }
  // -- EW Copy experiment end   --

  const { isLoading: isEwRefactorLoading } = useFeatureVariable(
    ENABLE_CMS_EW_REFACTOR
  )

  const { isLoading: isPaidLandersLoading } = usePaidLandersUpdate()

  const isLoading =
    isEwRefactorLoading ||
    isPaidLandersLoading ||
    isEwCopyLoading ||
    isLoadingFeWidget ||
    isLoadingUpdatedCp ||
    isLoadingDirectToAppUx

  const modules = {
    [EstimateWidgetVariations.FLOATING_LEAN]: (
      <FloatingWidget moduleData={moduleData} />
    ),
    [EstimateWidgetVariations.BOXED]: (
      <HeroBoxedWidget moduleData={moduleData} />
    ),
  }

  const ModuleToRender = modules[moduleData.variation as keyof typeof modules]

  return (
    <div className={styles.wrapper}>
      <Loader isLoading={isLoading} />
      {ModuleToRender}
    </div>
  )
}

export default EstimateWidget
