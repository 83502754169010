import React from 'react'

import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CloudinaryImage } from 'ethos-design-system'
import PropTypes from 'prop-types'

import PriorityImage from './components/global/PriorityImage'

// TODO this file could use some EDS color vars

export const AccordionToggleIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="18"
        height="11"
        viewBox="0 0 18 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.375 10.3901L9 1.68005L0.625 10.3901"
          stroke="black"
          strokeOpacity="0.85"
          strokeWidth="1.5"
        />
      </svg>
    </div>
  )
}

AccordionToggleIcon.propTypes = {
  className: PropTypes.string,
}

export const QuestionCtaArrow = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.00016 0.333374L5.82516 1.50837L10.4752 6.16671H0.333496V7.83337H10.4752L5.82516 12.4917L7.00016 13.6667L13.6668 7.00004L7.00016 0.333374Z" />
  </svg>
)

export const CardsArrow = () => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.904 0.956L11.856 4.832H0.703V6.143H11.837L7.904 10H9.918L14.421 5.478L9.918 0.956H7.904Z"
        fill="#272727"
      />
    </svg>
  )
}

export const PlayIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.39941 5L15.1996 10.8002L8.39941 16.6005V10.8002L8.39941 5Z"
        fill="#272727"
      />
      <circle cx="10.5" cy="10.5" r="10" stroke="black" />
    </svg>
  )
}

export const PriceArrows = () => (
  <svg
    width="40"
    height="12"
    viewBox="0 0 40 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 6H38.0003M2 6L6.5 1.5M2 6L6.5 10.5M38.0003 6L33.5 1.5M38.0003 6L33.5 10.5"
      stroke="#054742"
      strokeWidth="1.5"
      strokeMiterlimit="3.99933"
    />
  </svg>
)

export const SaveIcon = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6.625V7.875V15.875H14V7.875V6.625H15.25V17.125H0.75V6.625H2ZM8.63449 2.39277V11.875H7.38429V2.39277L4.91529 4.86136L4.03127 3.97748L8.00939 0L11.9875 3.97748L11.1035 4.86136L8.63449 2.39277Z"
      fill="grey"
      fillOpacity="0.85"
    />
  </svg>
)

export const BackAngularIcon = (props) => (
  <FontAwesomeIcon
    icon={faAngleLeft}
    size="2x"
    style={{ color: `var(--GraySecondary--opaque)` }}
    {...props}
  />
)

export const InfoIcon = (props) => (
  <FontAwesomeIcon
    icon={faInfoCircle}
    size="xs"
    style={{ color: `var(--GraySecondary--opaque)` }}
    {...props}
  />
)

export const InfoIconDirectToApp = () => {
  return (
    <div className="flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M7.33337 4.66668H8.66671V6.00001H7.33337V4.66668ZM7.33337 7.33334H8.66671V11.3333H7.33337V7.33334ZM8.00004 1.33334C4.32004 1.33334 1.33337 4.32001 1.33337 8.00001C1.33337 11.68 4.32004 14.6667 8.00004 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8.00001C14.6667 4.32001 11.68 1.33334 8.00004 1.33334ZM8.00004 13.3333C5.06004 13.3333 2.66671 10.94 2.66671 8.00001C2.66671 5.06001 5.06004 2.66668 8.00004 2.66668C10.94 2.66668 13.3334 5.06001 13.3334 8.00001C13.3334 10.94 10.94 13.3333 8.00004 13.3333Z"
          fill="#525252"
        />
      </svg>
    </div>
  )
}

export const CloseButtonCircle = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#F5F5F5" />
    <path
      d="M17.0606 16L20.2656 12.795L20.9266 12.1341C21.0241 12.0366 21.0241 11.8781 20.9266 11.7806L20.2194 11.0734C20.1219 10.9759 19.9634 10.9759 19.8659 11.0734L16 14.9394L12.1341 11.0731C12.0366 10.9756 11.8781 10.9756 11.7806 11.0731L11.0731 11.7803C10.9756 11.8778 10.9756 12.0363 11.0731 12.1338L14.9394 16L11.0731 19.8659C10.9756 19.9634 10.9756 20.1219 11.0731 20.2194L11.7803 20.9266C11.8778 21.0241 12.0362 21.0241 12.1337 20.9266L16 17.0606L19.205 20.2656L19.8659 20.9266C19.9634 21.0241 20.1219 21.0241 20.2194 20.9266L20.9266 20.2194C21.0241 20.1219 21.0241 19.9634 20.9266 19.8659L17.0606 16Z"
      fill="#727272"
    />
  </svg>
)

export const ArrowRight = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 13L16.67 13L11.08 18.59L12.5 20L20.5 12L12.5 4L11.09 5.41L16.67 11L4.5 11L4.5 13Z"
      fill="#272727"
    />
  </svg>
)

export const ArrowLeft = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 11H8.33L13.92 5.41L12.5 4L4.5 12L12.5 20L13.91 18.59L8.33 13H20.5V11Z"
      fill="#272727"
    />
  </svg>
)

export const ForbesLogo = () => (
  <img
    src="https://res.cloudinary.com/getethos/image/upload/v1695280191/Forbes_Advisor_Logo_White_Horizontal_hsrnjr.svg"
    title="Forbes"
    alt="Forbes"
  />
)

export const ForbesLogoMobile = () => (
  <img
    src="https://res.cloudinary.com/getethos/image/upload/v1706224055/Forbes_Advisor_Logo_White_Horizontal_ugyzji.svg"
    title="Forbes"
    alt="Forbes"
  />
)

export const BestMoneyLogo = () => (
  <PriorityImage
    publicId={
      'https://res.cloudinary.com/getethos/image/upload/v1707876670/BestMoney_logo_2_tbrdqm.png'
    }
    alt={'Best Money Logo'}
    crop={CloudinaryImage.CROP_METHODS.FIT}
    width={[200, 200, 200, 200]}
    height={[22, 22, 22, 22]}
  />
)
export const CreditKarmaLogo = () => (
  <PriorityImage
    publicId={
      'https://res.cloudinary.com/getethos/image/upload/v1708411413/IC-Logo_Karma-Green_RGB_ofnpzg.png'
    }
    alt={'Best Money Logo'}
    crop={CloudinaryImage.CROP_METHODS.FIT}
    width={[200, 200, 200, 200]}
    height={[24, 24, 24, 24]}
  />
)

export const ForbesUsaTodayLogo = () => (
  <img
    src={
      'https://res.cloudinary.com/getethos/image/upload/v1721372177/USAT_Blueprint_Logo_c1nnfq.png'
    }
    alt="Forbes USA Today Logo"
    className="max-w-[80px] sm:max-w-[100px]"
  />
)
