import Image from 'next/image'

import logoWhite from 'src/img/ethos-logo-white.svg'

import useDetectScreenSize from '@/hooks/useDetectScreenSize'

export const Banner = () => {
  const { isMobile } = useDetectScreenSize()
  return (
    <div className="flex items-center justify-between gap-2  border-b-[#C2C2C2] bg-[#05594F] px-5 py-4 md:justify-start md:gap-8 md:border-b-[1px] md:border-solid md:px-20">
      <Image
        src={logoWhite}
        alt="Ethos"
        priority
        width={isMobile ? 65 : 75}
        height={isMobile ? 16 : 14}
      />
      <div className="relative h-8 w-[223px]">
        <Image
          priority
          src="https://res.cloudinary.com/getethos/image/upload/v1728275603/banner-left-group_rpihm7.svg"
          fill
          alt="Ethos"
        />
      </div>
    </div>
  )
}
