import React from 'react'

import classNames from 'classnames'
import {
  COLORS,
  Caption2,
  TitleLarge2,
  TitleSmall2,
  TitleXLarge2,
} from 'ethos-design-system'
import CountUp from 'react-countup'

import { EstimateWidgetVariations } from '../../components/EstimateWidget/common/variations'
import { AVG_MONTHLY_DAYS, TOOLTIP } from '../../constants'
import { PriceArrows } from '../../icons'
import { DailyDollars } from './DailyDollars'
import { EstimateWidgetTooltip } from './EstimateWidgetTooltip'

const justDecimals = function (value: number): string {
  if (value % 1 != 0) {
    const decimals = value.toString().split('.')[1]
    if (decimals.length == 1) {
      return '.' + decimals + '0'
    }
    return '.' + decimals
  }
  return '.00'
}

const everythingButDecimals = function (value: number): string {
  if (value % 1 != 0) return value.toString().split('.')[0]
  return value.toString()
}

interface PriceRangeDisplayProps {
  // Must pass in styles since the current SCSS is too difficult to refactor
  styles: any
  minRangeStart: number
  minRangeEnd: number
  maxRangeStart: number
  maxRangeEnd: number
  variation?: string
  dailyDollars?: boolean
  dailyPremium?: boolean
  showSinglePrice?: boolean
  asta?: boolean
  finalExpense?: boolean
  directToApp?: boolean
}
const toolTipDetails = TOOLTIP.ESTIMATE_RATE_DAILY.MESSAGE
const toolTipLabel = TOOLTIP.ESTIMATE_RATE_DAILY.LABEL

/**
 * Displays a PriceRange. Countup is used for animation where
 * the component goes from RangeStart to RangeEnd
 */
export const PriceRangeDisplay: React.FC<PriceRangeDisplayProps> = ({
  styles,
  minRangeStart,
  minRangeEnd,
  maxRangeStart,
  maxRangeEnd,
  variation,
  dailyDollars,
  dailyPremium,
  showSinglePrice,
  asta,
  finalExpense,
  directToApp,
}) => {
  const minDailyValueStart = minRangeStart / AVG_MONTHLY_DAYS
  const minDailyValueEnd = minRangeEnd / AVG_MONTHLY_DAYS
  const maxDailyValueStart = maxRangeStart / AVG_MONTHLY_DAYS
  const maxDailyValueEnd = maxRangeEnd / AVG_MONTHLY_DAYS

  const floatingRangeDisplay = (
    <>
      {showSinglePrice ? (
        dailyPremium ? (
          <div className={[styles.singlePrice, styles.baseline].join(' ')}>
            <div className={styles.singlePriceWhole}>
              <TitleLarge2.Serif.Book500 color={COLORS.BRAND_FOREST}>
                <span>$</span>
                <CountUp
                  start={minDailyValueStart}
                  end={Math.max(0, minDailyValueEnd)}
                  decimals={2}
                  duration={2}
                />
              </TitleLarge2.Serif.Book500>
            </div>
            <div className={styles.dailyPremiumLabel}>&nbsp;&nbsp;/ day</div>
          </div>
        ) : (
          <div className={styles.singlePrice}>
            <TitleXLarge2.Serif.Book500 color={COLORS.BRAND_FOREST}>
              <CountUp
                start={minRangeStart}
                end={Math.max(0, minRangeEnd)}
                decimals={0}
                duration={2}
                prefix={'$'}
              />
            </TitleXLarge2.Serif.Book500>
          </div>
        )
      ) : (
        <div className={styles.ranges}>
          <div className={styles.price}>
            {dailyPremium ? (
              <div className={styles.dailyPremiumPrice}>
                <TitleLarge2.Serif.Book500>
                  <span>$</span>
                  <CountUp
                    start={minDailyValueStart}
                    end={Math.max(0, minDailyValueEnd)}
                    decimals={2}
                    duration={2}
                    formattingFn={everythingButDecimals}
                  />
                </TitleLarge2.Serif.Book500>
                <div className={styles.dailyPremiumDecimal}>
                  <TitleSmall2.Serif.Medium500>
                    <div className={styles.decimal}>
                      <CountUp
                        start={minDailyValueStart}
                        end={Math.max(0, minDailyValueEnd)}
                        decimals={2}
                        duration={2}
                        formattingFn={justDecimals}
                      />
                    </div>
                  </TitleSmall2.Serif.Medium500>
                  <div>
                    <Caption2.Regular400 color={COLORS.GRAY_SECONDARY}>
                      /day
                    </Caption2.Regular400>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.label}>
                  <Caption2.Regular400>From</Caption2.Regular400>
                </div>
                <TitleLarge2.Serif.Book500>
                  <CountUp
                    start={minRangeStart}
                    end={Math.max(0, minRangeEnd)}
                    decimals={0}
                    duration={2}
                    prefix={'$'}
                  />
                </TitleLarge2.Serif.Book500>
              </>
            )}
          </div>
          <div className={`${directToApp ? '' : 'mt-6'} flex items-center`}>
            <PriceArrows />
          </div>
          <div className={styles.price}>
            {dailyPremium ? (
              <div className={styles.dailyPremiumPriceRightmost}>
                <TitleLarge2.Serif.Book500>
                  <span>$</span>
                  <CountUp
                    start={maxDailyValueStart}
                    end={Math.max(0, maxDailyValueEnd)}
                    decimals={2}
                    duration={2}
                    formattingFn={everythingButDecimals}
                  />
                </TitleLarge2.Serif.Book500>
                <div className={styles.dailyPremiumDecimal}>
                  <TitleSmall2.Serif.Medium500>
                    <div className={styles.decimal}>
                      <CountUp
                        start={maxDailyValueStart}
                        end={Math.max(0, maxDailyValueEnd)}
                        decimals={2}
                        duration={2}
                        formattingFn={justDecimals}
                      />
                    </div>
                  </TitleSmall2.Serif.Medium500>
                  <div>
                    <Caption2.Regular400 color={COLORS.GRAY_SECONDARY}>
                      /day
                    </Caption2.Regular400>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.label}>
                  <Caption2.Regular400>To</Caption2.Regular400>
                </div>
                <TitleLarge2.Serif.Book500>
                  <CountUp
                    start={maxRangeStart}
                    end={Math.max(0, maxRangeEnd)}
                    decimals={0}
                    duration={2}
                    prefix={'$'}
                  />
                </TitleLarge2.Serif.Book500>
              </>
            )}
          </div>
        </div>
      )}
      {dailyDollars && (
        <DailyDollars start={minRangeStart} end={Math.max(0, minRangeEnd)} />
      )}
    </>
  )

  const experimentalRangeDisplay = (
    <>
      <div className={styles.astaRanges}>
        <div className={styles.astaPrice}>
          <TitleLarge2.Serif.Book500>
            <CountUp
              start={minDailyValueStart}
              end={Math.max(0, minDailyValueEnd)}
              decimals={2}
              duration={2}
              prefix={'$'}
            />
          </TitleLarge2.Serif.Book500>
        </div>
        <div className="mt-2 flex items-center">
          <PriceArrows />
        </div>
        <div className={styles.astaPrice}>
          <TitleLarge2.Serif.Book500>
            <CountUp
              start={maxDailyValueStart}
              end={Math.max(0, maxDailyValueEnd)}
              decimals={2}
              duration={2}
              prefix={'$'}
            />
          </TitleLarge2.Serif.Book500>
        </div>
      </div>
      <div
        className={classNames(styles.perDay, asta ? styles.withToolTip : '')}
      >
        <Caption2.Medium500>per day</Caption2.Medium500>
        {asta && (
          <EstimateWidgetTooltip
            details={toolTipDetails}
            label={toolTipLabel}
            popperBoxStyles={styles.tooltipPopperBoxStyles}
            placement="right"
            softCorners
          />
        )}
      </div>
    </>
  )

  const defaultRangeDisplay = (
    <div className={styles.ranges}>
      <div>
        <div className={styles.label}>
          <Caption2.Regular400>From</Caption2.Regular400>
        </div>
        <TitleLarge2.Sans.Regular400>
          <CountUp
            start={minRangeStart}
            end={Math.max(0, minRangeEnd)}
            decimals={0}
            duration={2}
            className={styles.price}
            prefix={'$'}
          />
        </TitleLarge2.Sans.Regular400>
      </div>
      <div>
        <div className={styles.label}>
          <Caption2.Regular400>To</Caption2.Regular400>
        </div>
        <TitleLarge2.Sans.Regular400>
          <CountUp
            start={maxRangeStart}
            end={Math.max(0, maxRangeEnd)}
            decimals={0}
            duration={2}
            className={styles.price}
            prefix={'$'}
          />
        </TitleLarge2.Sans.Regular400>
      </div>
    </div>
  )

  if (finalExpense)
    return (
      <div className={styles.finalExpenseRange}>
        <CountUp
          start={minRangeStart}
          end={Math.max(0, minRangeEnd)}
          decimals={2}
          duration={1}
          className={styles.price}
          prefix={'$'}
        />
      </div>
    )

  if (variation === EstimateWidgetVariations.FLOATING_LEAN) {
    return asta ? experimentalRangeDisplay : floatingRangeDisplay
  } else {
    return defaultRangeDisplay
  }
}
