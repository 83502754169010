import { FC } from 'react'

import { Button, Input } from '@getethos/ethos-design-system-v2'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { useEstimateWidgetContext } from '@/components/EstimateWidget/contexts/EstimateWidgetContext'
import { UserData } from '@/components/EstimateWidget/types/user'

import { GENDER_OPTIONS } from '../FinalExpenseInfoForm/utils/constants'
import styles from './DirectToAppInfoForm.module.scss'
import { ButtonGroup } from './components/ButtonGroup'
import CheckboxInput from './components/CheckboxInput/CheckboxInput'
import { ageMask, zipCodeMask } from './utils/masks'
import { validationSchema } from './utils/validation'

interface IDirectToAppInfoForm {
  onSubmit: (data: UserData) => void
}

type FormValues = {
  gender: string
  birthDate: string
  zipCode: string
  smoker?: boolean
}

const GENDER_OPTS = GENDER_OPTIONS.reverse().map((item) => ({
  copy: item.label,
  value: item.value,
}))

export const DirectToAppInfoForm: FC<IDirectToAppInfoForm> = ({ onSubmit }) => {
  const {
    userDataControls: { userData },
  } = useEstimateWidgetContext()

  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      gender: userData.gender || 'Male',
      smoker: userData.smoker || false,
      birthDate: userData.birthDate || '',
      zipCode: userData.zipCode || '',
    },
    resolver: yupResolver(validationSchema),
  })

  const isSmoker = watch('smoker')

  const values = watch()

  return (
    <div className="mx-auto mt-6 max-w-[420px] px-4 md:max-w-[500px] lg:mt-10">
      <h1 className={styles.header}>Get my instant estimate</h1>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit({
            ...data,
            smoker: data.smoker || false,
          })
        })}
        key={JSON.stringify(userData)}
      >
        <div className="flex flex-col gap-6">
          <ButtonGroup
            options={GENDER_OPTS}
            labelCopy="Gender"
            initialValue="Male"
          />
          <div className="flex gap-[13px]">
            <Input
              label="Age"
              type="number"
              name="birthDate"
              value={values.birthDate}
              mask={ageMask}
              pattern="\d*"
              errorMessage={errors.birthDate?.message}
              onChange={(e) => setValue('birthDate', e.target.value)}
            />
            <Input
              type="number"
              label="Zip Code"
              name="zipCode"
              value={values.zipCode}
              pattern="\d*"
              mask={zipCodeMask}
              errorMessage={errors.zipCode?.message}
              onChange={(e) => setValue('zipCode', e.target.value)}
            />
          </div>
          <CheckboxInput
            label="I currently use nicotine products"
            inputSize="md"
            name="smoker"
            checked={isSmoker}
            onChange={(e) =>
              setValue('smoker', e.target.checked, { shouldValidate: true })
            }
          />
        </div>
        <Button
          buttonTitle="Calculate now"
          variant="salamander"
          type="submit"
          size="lg"
          className="mt-12"
        />
      </form>
    </div>
  )
}
