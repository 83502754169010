import React, { useEffect, useState } from 'react'

import { useModuleContext } from '@/contexts/ModuleContext'
import { CloudinaryImage } from 'ethos-design-system'
import { ENABLE_CMS_EW_REFACTOR } from 'lib/FeaturesAndExperiments/featureStore'
import { useFeatureVariable } from 'lib/FeaturesAndExperiments/getFeatureVariable'

import { useDirectToAppUx } from '@/hooks/features/useDirectToAppUx'
import { usePaidLandersUpdate } from '@/hooks/features/usePaidLandersUpdate'

import { useIntersectionObserver } from '../../../../lib/@getethos/hooks/useIntersectionObserver'
import { ESTIMATE_WIDGET_AGES } from '../../../constants'
import {
  EstimateWidgetImageLayout,
  EstimateWidgetModuleData,
} from '../EstimateWidget'
import { EstimateWidgetProvider } from '../contexts/EstimateWidgetContext'
import styles from './Floating.module.scss'
import { FloatingFormManagerRefactor } from './FloatingForm/FloatingFormManagerRefactor'
import { FloatingFormManager } from './FloatingFormManager'
import {
  CoBranded,
  External,
  ForegroundImage,
  FullbleedImage,
  MultiImage,
  StraightToApp,
} from './Layouts'
import DirectToApp from './Layouts/DirectToApp/DirectToApp'
import { FinalExpense } from './Layouts/FinalExpense/FInalExpense'
import PaidLandersUpdate from './Layouts/PaidLandersUpdate'

export const FloatingWidget = ({
  moduleData,
}: {
  moduleData: EstimateWidgetModuleData
}) => {
  const [isIntersecting, scrollNode, updateScrollNode] =
    useIntersectionObserver()

  const {
    footerControls: { hideFooter },
    navBarControls: { hideNavBar },
  } = useModuleContext()

  const {
    isTreatment2: isPaidLandersTreatment2,
    isTreatment3: isPaidLandersTreatment3,
  } = usePaidLandersUpdate()

  const [widgetProgress, setWidgetProgress] = useState('info') // info or prices

  const { isEnabled } = useFeatureVariable(ENABLE_CMS_EW_REFACTOR)

  const isRefactor = isEnabled

  const formManager = !isRefactor ? (
    <FloatingFormManager
      scrollNode={scrollNode}
      isIntersecting={isIntersecting}
      moduleData={moduleData}
      minimumEligibleAge={ESTIMATE_WIDGET_AGES.MIN}
      maximumEligibleAge={ESTIMATE_WIDGET_AGES.MAX}
      progressFunction={setWidgetProgress}
    />
  ) : (
    <FloatingFormManagerRefactor
      moduleData={moduleData}
      scrollNode={scrollNode as unknown as Element}
    />
  )

  const { isTreatment } = useDirectToAppUx()

  useEffect(() => {
    if (isTreatment) {
      hideFooter()
      hideNavBar()
    }
  }, [isTreatment])

  // showPlaceholder can be used for experiments that modify the EW layout
  // to do this turn showPlaceholder into a useState constant and modify
  // it's value with useLayoutEffect once the Optimizely experiment flag
  // is ready to set the EW layout to it's final variation
  const showPlaceholder = false

  if (showPlaceholder)
    return (
      <div className={styles.placeholder}>
        {moduleData.groupImage && moduleData.imageAlt && (
          <CloudinaryImage
            publicId={moduleData.groupImage}
            alt={moduleData.imageAlt}
            className={styles.image}
            crop={CloudinaryImage.CROP_METHODS.CROP}
          />
        )}
      </div>
    )

  let moduleToRender: React.ReactNode

  if (isPaidLandersTreatment2 || isPaidLandersTreatment3) {
    moduleToRender = (
      <PaidLandersUpdate
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )
  } else if (isTreatment) {
    moduleToRender = (
      <DirectToApp
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )
  } else if (moduleData.imageLayout === EstimateWidgetImageLayout.MultiImage) {
    // MultiImage variation

    moduleToRender = (
      <MultiImage
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )
  } else if (
    moduleData.imageLayout === EstimateWidgetImageLayout.FinalExpense
  ) {
    moduleToRender = (
      <FinalExpense formManager={formManager} moduleData={moduleData} />
    )
  } else if (moduleData.imageLayout === EstimateWidgetImageLayout.CoBranded) {
    moduleToRender = (
      <CoBranded
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )
  } else if (moduleData.imageLayout === EstimateWidgetImageLayout.FullBleed) {
    moduleToRender = (
      <FullbleedImage
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )
  } else if (moduleData.imageLayout === EstimateWidgetImageLayout.External) {
    moduleToRender = (
      <External
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )
  } else if (
    moduleData.imageLayout === EstimateWidgetImageLayout.ForegroundImage
  ) {
    moduleToRender = (
      <ForegroundImage
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )
  } else if (
    moduleData.imageLayout === EstimateWidgetImageLayout.StraightToApp
  ) {
    moduleToRender = (
      <StraightToApp
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
        widgetProgress={widgetProgress}
        isRefactor={isRefactor}
      />
    )
  } else
    moduleToRender = (
      <FullbleedImage
        formManager={formManager}
        moduleData={moduleData}
        updateScrollNode={updateScrollNode}
      />
    )

  return <EstimateWidgetProvider>{moduleToRender}</EstimateWidgetProvider>
}
