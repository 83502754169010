import { COVERAGE_SLIDER, TOOLTIP } from '@/constants'
import { useLegalBannerContext } from '@/contexts/LegalBannerContext'
import { EstimateWidgetTooltip } from '@/shared/Estimate/EstimateWidgetTooltip'
import { PriceRangeDisplay } from '@/shared/Estimate/PriceRangeDisplay'
import { CoverageSlider } from '@/shared/Estimate/Sliders'
import { Button } from '@getethos/ethos-design-system-v2'
import classNames from 'classnames'
import { Product } from 'lib/@getethos/constants'

import { FinalExpenseCoverageSlider } from '@/components/EstimateWidget/common/FinalExpenseCoverageSlider'
import { DATA_TID } from '@/components/EstimateWidget/common/data-tid'
import { EstimateWidgetVariations } from '@/components/EstimateWidget/common/variations'
import { useEstimateQuotesContext } from '@/components/EstimateWidget/contexts/EstimateQuotesContext'

import useDetectScreenSize from '@/hooks/useDetectScreenSize'

import floatingStyles from '../../../Floating.module.scss'
import { getStyles } from '../../FloatingRangeForm/FloatingRangeFormRefactor'
import styles from './DirectToAppRangeForm.module.scss'
import { TermButtons } from './components/TermButtons/TermButton'
import { TrustSignals } from './components/TrustSignals/TrustSignals'

export const DirectToAppRangeForm = () => {
  const { isMobile } = useDetectScreenSize()

  const {
    userSelectedValues,
    maxCoverage,
    handleCoverageChange,
    handleTermChange,
    previousRanges,
    ranges,
    terms,
    product,
    handleGoToMainApp,
  } = useEstimateQuotesContext()

  const { pricesClassName } = getStyles({
    asta: false,
    showSinglePrice: false,
    finalExpense: false,
  })

  const goToApp = () => handleGoToMainApp({})

  const shouldRenderFinalExpenseSlider = product === Product.FE

  const { isDisclaimerOpen } = useLegalBannerContext()

  return (
    <section
      className={classNames(
        'mt-6 px-4 lg:mt-10',
        isDisclaimerOpen ? 'mb-32' : 'mb-4'
      )}
    >
      <div className={classNames('mx-auto max-w-[500px]')}>
        <div className="rounded-3xl border-[1px] border-solid border-[--primary-cypress-15] px-5 py-6 lg:px-10 lg:py-10">
          <div className="mb-2 flex items-center">
            <h3
              className={classNames(
                isMobile ? 'display-s-med' : 'display-m-med',
                'text-[#272727]'
              )}
            >
              Your estimate
            </h3>
            {!isMobile && (
              <EstimateWidgetTooltip
                popperBoxStyles={floatingStyles.tooltipPopperBoxStyles}
                placement="right"
                softCorners
                directToApp
                details={TOOLTIP.ESTIMATE_RATE_DAILY.MESSAGE}
                label={TOOLTIP.ESTIMATE_RATE_DAILY.LABEL}
              />
            )}
          </div>
          <div
            className={classNames(pricesClassName.join(' '), 'max-w-[300px]')}
          >
            <PriceRangeDisplay
              styles={floatingStyles}
              minRangeStart={previousRanges.min}
              minRangeEnd={ranges.min}
              maxRangeStart={previousRanges.max}
              maxRangeEnd={ranges.max}
              variation={EstimateWidgetVariations.FLOATING_LEAN}
              dailyPremium
              directToApp
            />
          </div>
          <div className={styles.sliderWrapper}>
            {!shouldRenderFinalExpenseSlider && (
              <CoverageSlider
                coverage={userSelectedValues?.coverage || 0}
                minCoverage={COVERAGE_SLIDER.MIN}
                maxCoverage={maxCoverage}
                coverageStep={COVERAGE_SLIDER.STEP}
                handleCoverageChange={handleCoverageChange}
                dataTid={DATA_TID.COVERAGE_INPUT}
                styles={floatingStyles}
                variation={EstimateWidgetVariations.FLOATING_LEAN}
                enableTooltip
                coverageLabel="Coverage amount"
                directToApp
              />
            )}
            {shouldRenderFinalExpenseSlider && (
              <FinalExpenseCoverageSlider
                coverage={userSelectedValues?.coverage || 0}
                maxCoverage={maxCoverage}
                handleCoverageChange={handleCoverageChange}
                variation={EstimateWidgetVariations.FLOATING_LEAN}
                styles={floatingStyles}
                coverageLabel="Coverage amount"
                directToApp
              />
            )}
          </div>
          {!shouldRenderFinalExpenseSlider && (
            <div className="mt-4 lg:mt-8">
              <TermButtons
                maxTerm={terms[terms.length - 1]}
                initialTerm={userSelectedValues?.term || terms[0]}
                termToggle={handleTermChange}
              />
            </div>
          )}
          <Button
            buttonTitle="Get my final rate"
            variant="salamander"
            size="lg"
            className="mt-8"
            onClick={goToApp}
          />
        </div>
        <div className="body-s-reg mt-6 text-center">
          This tool is intended for estimation purposes only.
        </div>
        <div className="mt-8 lg:mt-14">
          <TrustSignals />
        </div>
      </div>
    </section>
  )
}
