import React from 'react'

import { LayoutProps } from '..'

const DirectToApp = ({ updateScrollNode, formManager }: LayoutProps) => {
  return (
    <section ref={updateScrollNode}>
      {formManager && <div>{formManager}</div>}
    </section>
  )
}

export default DirectToApp
